import ResultService from '@/utils/services/ResultService';

export default class SurveyService {
  static async submitAnswers(lang, answers) {
    const sessionId = localStorage.getItem('session_id');
    
    const user = { id: sessionId, lang };

    const { data } = await ResultService.createResults(user, answers);

    localStorage.setItem('results', JSON.stringify(data.results));
    localStorage.setItem('user_id', data.id);

    return data.id;
  }

  static getProgress(current, length) {
    return Math.round((current / length) * 100);
  }
}
