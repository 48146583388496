const formFr = [
  {
    key: 'company',
    label: 'Quel est le nom de votre entreprise ? ',
    type: 'input',
    required: true,
  },
  {
    key: 'job_title',
    label: 'Quelle est votre fonction ? ',
    type: 'input',
    required: true,
  },
  {
    key: 'gas_card',
    label: "Disposez-vous d'une carte de carburant ?",
    type: 'select',
    choices: [
      {
        label: 'Oui',
        value: 1,
      },
      {
        label: 'Non',
        value: 0,
      },
    ],
    required: true,
  },
  {
    key: 'charging_card',
    label: "Disposez-vous d'une carte de recharge ?",
    type: 'select',
    choices: [
      {
        label: 'Oui',
        value: 1,
      },
      {
        label: 'Non',
        value: 0,
      },
    ],
    required: true,
  },
];

const formNl = [
  {
    key: 'company',
    label: 'Wat is de naam van je bedrijf? ',
    type: 'input',
    required: true,
  },
  {
    key: 'job_title',
    label: 'Wat is je functie?',
    type: 'input',
    required: true,
  },
  {
    key: 'gas_card',
    label: 'Heb je een tankkaart?',
    type: 'select',
    choices: [
      {
        label: 'Ja',
        value: 1,
      },
      {
        label: 'Nee',
        value: 0,
      },
    ],
    required: true,
  },
  {
    key: 'charging_card',
    label: 'Heb je een laadpas?',
    type: 'select',
    choices: [
      {
        label: 'Ja',
        value: 1,
      },
      {
        label: 'Nee',
        value: 0,
      },
    ],
    required: true,
  },
];

export { formFr, formNl };
