<template>
  <form class="survey-form">
    <p class="form-err">{{ errors.global }}</p>
    <div class="mb-3">
      <p class="form-label">{{ $t('survey.firstnameLabel') }}<sup>*</sup></p>
      <el-input
        type="text"
        v-model="user.firstname"
        @change="handleChange('firstname')"
      />
    </div>
    <div class="mb-3">
      <p class="form-label">{{ $t('survey.emailLabel') }}<sup>*</sup></p>
      <el-input
        type="email"
        v-model="user.email"
        @blur="formatEmail"
        @change="handleChange('email')"
      />
      <p class="form-err">{{ errors.email }}</p>
    </div>
    <template v-if="user_type === 0">
      <div class="form-item mb-3" v-for="item in form" :key="item.key">
        <template v-if="item.type === 'input'">
          <p class="form-label">{{ item.label }}<sup>*</sup></p>
          <el-input
            type="text"
            v-model="user[item.key]"
            @change="handleChange(item.key)"
            required
          />
        </template>
        <template v-if="item.type === 'select'">
          <p class="form-label">{{ item.label }}<sup>*</sup></p>
          <el-select
            v-model="user[item.key]"
            v-if="item.type === 'select'"
            @change="handleChange(item.key)"
            :placeholder="$t('survey.selectPlaceholder')"
            required
          >
            <el-option
              v-for="option in item.choices"
              :key="option.value"
              :label="option.label"
              :value="option.value"
            />
          </el-select>
        </template>
      </div>
    </template>
    <div class="form-check">
      <input
        class="form-check-input"
        type="checkbox"
        v-model="user.checked"
        @change="handleChange('checked')"
      />
      <label class="form-label"> {{ $t('optin.checkbox') }}<sup>*</sup> </label>
      <p class="form-err">
        {{ errors.checked }}
      </p>
    </div>
  </form>
</template>

<script>
import { formFr, formNl } from '@/utils/data/form';

export default {
  name: 'SurveyForm',
  props: ['user_type'],
  emits: ['changed'],
  data() {
    return {
      lang: 'nl',
      user: {
        firstname: '',
        email: '',
        company: '',
        job_title: '',
        gas_card: '',
        charging_card: '',
        checked: false,
      },
      errors: {
        email: null,
        checked: null,
        global: null,
      },
      isValid: false,
    };
  },
  computed: {
    form() {
      return this.lang === 'fr' ? formFr : formNl;
    },
  },
  created() {
    this.lang = this.$i18n.locale;
  },
  methods: {
    formatEmail() {
      this.errors.email = ''
      if (!this.user.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
        this.errors.email = this.$t('optin.emailErr');
        return;
      }

      this.user.email = this.user.email.toLowerCase();
    },
    handleChange(key) {
      let isValid = true;

      const requiredFields =
        this.user_type === 1
          ? ['firstname', 'email', 'checked']
          : Object.keys(this.user);

      for (let i in requiredFields) {
        const field = requiredFields[i];
        if (
          this.user[field] === '' ||
          this.user[field] === null ||
          this.user[field] === undefined
        ) {
          isValid = false;
        }
      }

      if (key === 'checked') this.handleChecked(isValid);

      this.$emit('changed', key, this.user[key], isValid);
    },
    handleChecked(isValid) {
      this.errors.global = '';
      if (!this.user.checked && !isValid) this.errors.global = this.$t('survey.missing_fields');
    },
  },
};
</script>
