<template>
  <div class="input-number">
    <el-input-number
      v-model="choice"
      :min="0"
      :max="50"
      :step="1"
      :placeholder="item.placeholder"
      :label="item.metric"
      @change="setChoice"
    />
  </div>
</template>

<script>
export default {
  name: 'NumberType',
  props: ['item'],
  emits: ['setChoice'],
  data() {
    return {
      choice: 0,
    };
  },
  methods: {
    setChoice(value) {
      if (value) {
        this.choice = Math.round(value);
        this.$emit('setChoice', this.choice);
      }
    },
  },
};
</script>