const surveyFr = [
  {
    key: 'size',
    type: 'ChoiceType',
    tooltip: true,
    info: "Faites correspondre la taille de la voiture avec votre budget d'achat (question suivante) pour une correspondance optimale. Une grande voiture coûte généralement plus cher qu'une voiture moyenne ou petite.",
    question: 'Quelle serait la taille de votre prochaine voiture?',
    choices: [
      {
        choice: 'Petite',
        sub: '(Renault Clio, Toyota Yaris, Hyundai i10,..)',
        value: 1,
      },
      {
        choice: 'Moyenne',
        sub: '(Volkswagen Taigo, Ford Focus, Kia Niro,...)',
        value: 2,
      },
      {
        choice: 'Grande',
        sub: '(Volvo XC60, BMW X3, Toyota Mirai,...)',
        value: 3,
      },
    ],
  },
  {
    key: 'budget',
    type: 'ChoiceType',
    tooltip: true,
    info: "Faites correspondre votre budget d'achat et la taille de la voiture (question précédente) pour une adéquation optimale du carburant. Une grande voiture coûte généralement plus cher qu'une voiture moyenne ou petite.",
    question: "Quel est votre budget d'achat?",
    choices: [
      {
        choice: 'Moins de 25 000€',
        value: 1,
      },
      {
        choice: '25 000 - 40 000€',
        value: 2,
      },
      {
        choice: '41 000 - 60 000€',
        value: 3,
      },
      {
        choice: 'Plus de 60 000€',
        value: 4,
      },
    ],
  },
  {
    key: 'distance',
    type: 'ChoiceType',
    tooltip: false,
    question:
      'Combien de kilomètres parcourez-vous en moyenne chaque jour en voiture?',
    choices: [
      {
        choice: 'Moins de 50 km/jour',
        value: 49,
      },
      {
        choice: '50 - 180 km/jour',
        value: 125,
      },
      {
        choice: '180 - 280 km/jour',
        value: 230,
      },
      {
        choice: '280 - 400 km/jour',
        value: 340,
      },
      {
        choice: 'Plus de 400 km/jour',
        value: 401,
      },
    ],
  },
  {
    key: 'trips',
    type: 'NumberType',
    tooltip: true,
    question:
      'Combien de trajets en voiture effectuez-vous en moyenne quotidiennement?',
    metric: 'Trajets/jour',
    placeholder: 'Ex: 4',
    info: 'Comptez également les petits trajets',
  },
  {
    key: 'charge',
    type: 'ChoiceType',
    tooltip: true,
    question:
      'Auriez-vous la possibilité de recharger une voiture électrique, maintenant ou dans un avenir proche?',
    info: "Vous pouvez déjà recharger votre voiture à la maison, au travail ou dans un parking public, ou vous pensez pouvoir le faire à l'avenir ? Dans ce cas, répondez « oui ». Si vous répondez « non » à cette question, nous ne vous conseillerons pas de voitures électriques à batterie.",
    choices: [
      {
        choice: 'Oui',
        value: 1,
      },
      {
        choice: 'Non',
        value: 0,
      },
    ],
  },
  {
    key: 'station',
    type: 'ChoiceType',
    tooltip: true,
    info: 'Pouvez-vous installer une station de recharge à la maison maintenant ou dans un avenir proche et/ou pouvez-vous recharger au travail?',
    question:
      'Pouvez-vous maintenant ou dans un avenir proche recharger au travail ou sur la route?',
    choices: [
      {
        choice: 'Oui',
        value: 1,
      },
      {
        choice: 'Non',
        value: 0,
      },
    ],
  },
  {
    key: 'zip',
    type: 'ZipType',
    tooltip: false,
    question: 'Où habitez-vous?',
    explanation:
      'Nous vous le demandons afin de pouvoir déterminer quels carburants et quelles stations de recharge sont disponibles dans votre région ainsi que les taxes qui vous concernent.',
    disclaimer: 'Seuls les codes postaux belges peuvent être traités.',
    placeholder: 'Ex: 9000',
  },
  {
    key: 'tax',
    type: 'ChoiceType',
    tooltip: false,
    question:
      'Pouvez-vous, vous ou votre employeur, déduire votre voiture fiscalement?',
    choices: [
      {
        choice: 'Oui',
        value: 1,
      },
      {
        choice: 'Non',
        value: 0,
      },
    ],
  },
  {
    key: 'env_percentage',
    type: 'ChoiceType',
    tooltip: true,
    info: 'Le budget comprend le prix d’achat, l’entretien, le coût du carburant, la taxe de mise en circulation et la taxe de circulation annuelle. La durabilité implique la totalité des émissions dégagées lors de la production de la voiture et des carburants, mais aussi lors de la conduite du véhicule.',
    question:
      'Choisissez ci-dessous dans quelle mesure la durabilité est importante dans votre choix de carburant :',
    choices: [
      {
        choice: 'Importante',
        value: 100,
      },
      {
        choice: 'Neutre',
        value: 50,
      },
      {
        choice: 'Pas importante',
        value: 0,
      },
    ],
  },
  {
    key: 'user_type',
    type: 'ChoiceType',
    tooltip: false,
    question: 'Achetez-vous la voiture en tant que :',
    disclaimer: 'Nous vous demandons cette information afin de pouvoir vous informer sur les produits et services les plus adaptés à votre profil.',
    choices: [
      {
        choice: 'Personne privée',
        value: 1
      },
      {
        choice: 'Entreprise',
        value: 0
      }
    ]
  },
];

const surveyNl = [
  {
    key: 'size',
    type: 'ChoiceType',
    tooltip: true,
    info: 'Stem de grootte van de auto en je aankoopbudget (volgende vraag) op elkaar af voor een optimale brandstofmatch. Een grote auto kost doorgaans meer dan een medium of kleine auto.',
    question: 'Hoe groot is je toekomstige auto?',
    choices: [
      {
        choice: 'Klein',
        sub: '(bv. Renault Clio, Toyota Yaris, Hyundai i10,...)',
        value: 1,
      },
      {
        choice: 'Medium',
        sub: '(bv. Volkswagen Taigo, Ford Focus, Kia Niro,...)',
        value: 2,
      },
      {
        choice: 'Groot',
        sub: '(bv. Volvo XC60, BMW X3, Toyota Mirai,...)',
        value: 3,
      },
    ],
  },
  {
    key: 'budget',
    type: 'ChoiceType',
    tooltip: true,
    info: 'Stem je aankoopbudget en de grootte van de auto (vorige vraag) op elkaar af voor een optimale brandstofmatch. Een grote auto kost doorgaans meer dan een medium of kleine auto.',
    question: 'Wat is je aankoopbudget?',
    choices: [
      {
        choice: 'Minder dan €25.000',
        value: 1,
      },
      {
        choice: '€25.000 - €40.000',
        value: 2,
      },
      {
        choice: '€41.000 - €60.000',
        value: 3,
      },
      {
        choice: 'Meer dan €60.000',
        value: 4,
      },
    ],
  },
  {
    key: 'distance',
    type: 'ChoiceType',
    tooltip: false,
    question: 'Hoeveel kilometer rij je elke dag gemiddeld met de auto?',
    choices: [
      {
        choice: 'Minder dan 50 km/dag',
        value: 49,
      },
      {
        choice: '50 - 180 km/dag',
        value: 125,
      },
      {
        choice: '180 - 280 km/dag',
        value: 230,
      },
      {
        choice: '280 - 400 km/dag',
        value: 340,
      },
      {
        choice: 'Meer dan 400 km/dag',
        value: 401,
      },
    ],
  },
  {
    key: 'trips',
    type: 'NumberType',
    tooltip: true,
    question: 'Hoeveel autoritten doe je elke dag gemiddeld?',
    metric: 'Ritten/dag',
    placeholder: 'Bv. 4',
    info: 'Tel ook kortere ritten mee',
  },
  {
    key: 'charge',
    type: 'ChoiceType',
    tooltip: true,
    question:
      'Zou je nu of in de toekomst een elektrische auto kunnen opladen?',
    info: "Kan je nu al thuis, op het werk of op een openbare parking je auto opladen, of denk je dat je dat in de toekomst zal kunnen doen? Antwoord dan ‘ja’. Als je op deze vraag ‘neen’ antwoordt, stellen we je geen batterij-elektrische auto voor.",
    choices: [
      {
        choice: 'Ja',
        value: 1,
      },
      {
        choice: 'Nee',
        value: 0,
      },
    ],
  },
  {
    key: 'station',
    type: 'ChoiceType',
    tooltip: true,
    info: 'Kan je nu of binnenkort op het werk of onderweg bijladen?',
    question: 'Zou je een elektrische auto kunnen bijladen tussen de ritten?',
    choices: [
      {
        choice: 'Ja',
        value: 1,
      },
      {
        choice: 'Nee',
        value: 0,
      },
    ],
  },
  {
    key: 'zip',
    type: 'ZipType',
    tooltip: false,
    question: 'Waar woon je?',
    explanation:
      'We vragen dit zodat we kunnen bepalen welke brandstoffen en laadpalen rondom je thuisomgeving aanwezig zijn en welke taksen voor jou van toepassing zijn.',
    disclaimer: 'Enkel Belgische postcodes kunnen verwerkt worden.',
    placeholder: 'Bv: 9000',
  },
  {
    key: 'tax',
    type: 'ChoiceType',
    tooltip: false,
    question: 'Kan jij of je werkgever de auto fiscaal inbrengen?',
    choices: [
      {
        choice: 'Ja',
        value: 1,
      },
      {
        choice: 'Nee',
        value: 0,
      },
    ],
  },
  {
    key: 'env_percentage',
    type: 'ChoiceType',
    tooltip: true,
    info: 'Budget omvat aankoopprijs, onderhoud, brandstofkost, belasting op inverkeerstelling en jaarlijkse wegentaks. Duurzaamheid gaat over uitstoot tijdens de productie van auto’s en brandstoffen, en uitstoot bij het rijden.',
    question:
      'Kies hieronder in welke mate duurzaamheid belangrijk is in je brandstofkeuze:',
    choices: [
      {
        choice: 'Belangrijk',
        value: 100,
      },
      {
        choice: 'Neutraal',
        value: 50,
      },
      {
        choice: 'Niet belangrijk',
        value: 0,
      },
    ],
  },
  {
    key: 'user_type',
    type: 'ChoiceType',
    tooltip: false,
    question: 'Koop je de auto aan als:',
    disclaimer: 'We vragen dit zodat we je kunnen informeren over de producten en diensten die het meest aangepast zijn aan jouw profiel.',
    choices: [
      {
        choice: 'als particulier',
        value: 1
      },
      {
        choice: 'als bedrijf',
        value: 0
      }
    ]
  },
];

export { surveyFr, surveyNl };
