<template>
  <div class="survey-item">
    <div class="card-title" v-if="!showForm">
      <h3 class="mb-4">
        {{ item.question }}
        <el-tooltip
          v-if="item.tooltip"
          :content="item.info"
          placement="bottom"
          effect="light"
        >
          <i class="bi bi-info-circle-fill"></i>
        </el-tooltip>
      </h3>
    </div>
    <component
      :is="item.type"
      :item="item"
      @setChoice="setChoice"
      v-if="!showForm"
    />
    <survey-form v-if="showForm" :user_type="choice" @changed="handleChange" />
    <div class="survey-nav">
      <p @click="previous" v-show="index !== 0">
        {{ $t('survey.previous') }}
      </p>
      <div></div>
      <app-button @clicked="nextQuestion" :disabled="disabled">{{
        $t('survey.next')
      }}</app-button>
    </div>
  </div>
</template>

<script>
import ChoiceType from '@/components/survey/types/ChoiceType';
import NumberType from '@/components/survey/types/NumberType';
import ZipType from '@/components/survey/types/ZipType';
import SurveyForm from '@/components/survey/Form';

export default {
  name: 'SurveyItem',
  components: {
    ChoiceType,
    NumberType,
    ZipType,
    SurveyForm,
  },
  props: ['item', 'index'],
  emits: ['next', 'previous', 'showEnv', 'createUser'],
  data() {
    return {
      choice: null,
      disabled: true,
      showForm: false,
      user: {
        firstname: '',
        email: '',
        company: null,
        job_title: null,
        gas_card: null,
        charging_card: null,
        checked: false,
      },
    };
  },
  methods: {
    // show form depending on user_type and user next button to submit form
    setChoice(value) {
      this.choice = value;

      if (this.item.key === 'user_type') {
        this.showForm = true;
        return;
      }

      this.disabled = false;
    },
    nextQuestion() {
      if (this.item.key === 'env_percentage') this.setPercentage();
      if (this.item.key === 'user_type')
        this.$emit('createUser', { ...this.user, user_type: this.choice });
      this.$emit('next', this.choice, this.item.key);
    },
    previous() {
      this.choice = '';

      if (this.showForm) {
        this.showForm = false;
        return;
      }

      this.$emit('previous', this.index, this.item.key);
    },
    setPercentage() {
      const chosen = this.item.choices.find(
        (choice) => choice.value === this.choice
      );

      localStorage.setItem('env_percentage', chosen.choice);
      this.$emit('showEnv', chosen.choice);
    },
    handleChange(key, value, isValid) {
      this.user[key] = value;
      if (isValid && this.user.checked) this.disabled = false;
      else this.disabled = true;
    },
  },
};
</script>

<style lang="scss">
.survey-item {
  max-width: 32rem;
  margin: 0 auto;
  .card-title {
    text-align: center;
    font-family: $accent-font;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 1rem;
    i {
      color: $dark-bg;
      font-size: 25px;
      cursor: pointer;
      padding-left: 0.5rem;
    }
  }
  .card {
    text-align: center;
    margin: 0 auto;
    cursor: pointer;
    max-width: 17rem;
    margin-bottom: 1rem;
    transition: all 0.2s ease-in-out;
    border: 1px solid $primary;
    border-radius: 4px;
    box-shadow: 0px 3px 6px #00000029;
    .card-content {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      color: $dark-bg;
    }
    &:hover,
    &:focus,
    &.card-active {
      transform: scale(1.05);
      background-color: $light-bg;
      font-weight: 600;
    }
  }
  .survey-nav {
    margin-top: 3rem;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    p {
      color: $info;
      text-decoration: underline;
      text-underline-offset: 5px;
      cursor: pointer;
    }
  }
  .input-number {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .input-zip {
    .el-select {
      display: flex;
      justify-content: center;
      margin: 0 auto;
      padding-bottom: 2rem;
      max-width: fit-content !important;
    }
  }
}

@include bp-down(md) {
  .survey-item {
    .card-title {
      margin: 0 auto;
      i {
        font-size: 20px;
      }
    }
    .card-content {
      font-size: 1rem;
    }
    .survey-nav {
      margin-top: 2rem;
    }
  }
}
</style>
