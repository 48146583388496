import api from '@/utils/services/api';

export default class ZipService {
  static async getZipsByLang(lang) {
    try {
      const { data } = await api.get(`/zipcodes?lang=${lang}`);
      return data;
    } catch (err) {
      console.error(err);
    }
  }
}
