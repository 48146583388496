<template>
  <div class="input-zip">
    <p class="mb-4">{{ item.explanation }}</p>
    <el-select
      v-model="choice"
      @change="setChoice"
      filterable
      :placeholder="item.placeholder"
      :remote="true"
      :loading="isLoading"
    >
      <el-option
        v-for="(zip, index) in zipcodes"
        :key="zip.code"
        :label="`${zip.code} - ${zip.city}`"
        :value="index"
      >
      </el-option>
    </el-select>
    <p>{{ item.disclaimer }}</p>
  </div>
</template>

<script>
import ZipService from '@/utils/services/ZipService';

export default {
  name: 'ZipType',
  props: ['item'],
  data() {
    return {
      zipcodes: [],
      choice: '',
      isLoading: true,
    };
  },
  created() {
    ZipService.getZipsByLang(this.$i18n.locale)
      .then((zips) => {
        this.zipcodes = zips;
        this.isLoading = false;
      })
      .catch(() => {
        this.isLoading = false;
      });
  },
  methods: {
    setChoice(index) {
      this.choice = index;
      const value = this.zipcodes[index].code;
      this.$emit('setChoice', value);
    },
  },
};
</script>
